/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "../node_modules/material-design-icons-iconfont/src/material-design-icons";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables
@import "../node_modules/@angular/material/_theming.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// AWS Amplify styles
@import '../node_modules/@aws-amplify/ui-angular/theme.css';

// Theme customization
@import "theme/theme";
