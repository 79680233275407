/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.

// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@use "@angular/material" as mat;
@import "../../node_modules/@angular/material/_theming.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.define-palette(mat.$indigo-palette, 500);
// stylelint-disable-next-line value-keyword-case
$app-accent: mat-palette(mat.$pink-palette, A200, A100, A400);

// Create the theme object (a Sass map containing all of the palettes).

$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
